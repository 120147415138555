import { useEffect, useState } from 'react';

// Importing styling
import './App.css';

// Importing components
import Cookie from './components/app/cookie';
import Navbar from './components/navbar/navbar';
import Section_cover from './components/section/section_cover';
import Section_about from './components/section/section_about';
import Section_webapp from './components/section/section_webapp';
import Section_techno from './components/section/section_techno';
import Section_workflow from './components/section/section_workflow';
import Section_design from './components/section/section_design';
import Section_contact from './components/section/section_contact';
import Section_contact_form from './components/section/section_contact_form';
import App_chat from './components/app/app_chat';
import Footer from './components/footer/footer';

function App() {
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookiePreference = localStorage.getItem('cookieAccepted');
    if (cookiePreference === null) {
      setShowCookieBanner(true);
    }
  }, []);

  return (
    <>
      {showCookieBanner && <Cookie />}
      <Navbar />
      <App_chat />
      <Section_cover />
      <Section_about />
      <Section_webapp />
      <Section_techno />
      <Section_workflow />
      <Section_design />
      <Section_contact />
      <Section_contact_form />
      <Footer />
    </>
  );
}

export default App;