import './footer.css'

function Footer() {
    return (
        <footer className="footer">
            <div className='footer_container'>
                <div className='footer_content_left'>
                    <p className='footer_content_left_title'>
                        GET IN TOUCH !
                    </p>
                    <p>
                        I specialize in creating user-centered designs that are both beautiful and functional. With my skills in full-stack development, I bring these designs to life with clean, efficient code.
                    </p>
                </div>
                <div className='footer_content_right'>
                    <a href='https://www.instagram.com/expience/' target='_blank' rel='noreferrer' aria-label='link to the instagram profil'>
                        <img src="/svg/logo_instagram.svg" alt="" />
                    </a>
                    <a href='https://www.behance.net/DuverneyDesign' target='_blank' rel='noreferrer' aria-label='link to the behance profil'>
                        <img src="/svg/logo_behance.svg" alt="" />
                    </a>
                </div>
            </div>
            <div className='footer_watermark'>
                <p>Designed by Stephane Duverney // © 2024 - All rights reserved</p>
            </div>
        </footer>
    );
}

export default Footer;