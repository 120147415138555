import './section_techno.css';

function section_techno() {
    return (
        <section className='section_techno_container'>
            <div className='section_techno_container-title_container'>
                <div className='section_techno_container-title'>
                    Technologies
                </div>
                <div className='section_techno_containe-subtitle'>
                    Full Stack
                </div>
                <div className='section_techno_container-icon_container'>
                    <div>
                        <img src="/svg/icon_react.svg" alt="" />
                        <div>
                            FrontEnd
                        </div>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <hr style={{ width: "100%" }} />
                    </div>
                    <div>
                        <img src="/svg/icon_nodejs.svg" alt="" />
                        <div>
                            BackEnd
                        </div>
                    </div>
                    <div>
                        <img src="" alt="" />
                        <hr style={{ width: "100%" }} />
                    </div>
                    <div>
                        <img src="/svg/icon_mongodb.svg" alt="" />
                        <div>
                            Database
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default section_techno;