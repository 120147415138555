import './section_webapp.css'

function section_webapp() {
    return (
        <section className="section_webapp">
            <div className="section_webapp_container">
                <div className='section_web_container_title'>
                    <img src="/svg/section_webdesign_title.svg" alt="" />
                </div>
                <div className='section_web_container_sub-title'>
                    <div>
                    </div>
                </div>
                <div className='section_web_container_text-container'>
                    <div className='section_web_container_text-container_content-left'>
                        <div className='section_web_container_text-container_content_title'>My process</div>
                        <div>
                            My process begins with thorough research to understand the users, their needs, and the problem space. From there, I brainstorm and design user-centered solutions, creating intuitive, functional layouts. I develop interactive prototypes to gather feedback, iterating to refine the design. With full-stack skills, I seamlessly transition to development, writing clean, efficient code to bring the designs to life. Post-launch, I continuously monitor performance and user feedback, making adjustments to ensure the product remains effective and user-friendly.                        </div>
                    </div>
                    <div className='section_web_container_text-container_content-right'>
                        <div className='section_web_container_text-container_content_title'>Why me ?</div>
                        <div>
                            As both a product designer and full-stack developer, I bring a unique blend of creativity and technical expertise to every project. I don’t just create beautiful designs—I build solutions that are deeply rooted in user needs and backed by efficient, scalable code. My holistic approach ensures seamless collaboration between design and development, reducing friction and streamlining the process from concept to launch. I’m committed to delivering high-quality, user-focused products, with a strong attention to detail and a mindset for continuous improvement. With me, you get a partner who’s invested in both the success of your product and the experience of your users.
                        </div>
                    </div>
                </div>
                <div className='section_web_container_graph'>
                    <img src="/svg/icon_graph.svg" alt="" />
                </div>
            </div>
        </section>
    );
}

export default section_webapp;