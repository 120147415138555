import './section_contact_form.css';
import { useState } from 'react';

function Section_form() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [services, setServices] = useState([]);
    const [formule, setFormule] = useState([]);
    const [delay, setDelay] = useState([]);
    const [details, setDetails] = useState('');

    const handleCheckboxChange = (e, setter) => {
        const { value, checked } = e.target;
        if (checked) {
            setter(prev => [...prev, value]);
        } else {
            setter(prev => prev.filter(val => val !== value));
        }
    };

    function SendMail(e) {
        e.preventDefault();

        // Construire l'URL mailto:
        const mailtoLink = `mailto:hello@stephane-duverney.fr?subject=Contact Form Submission&body=Hello Stéphane,
        
I contacted you because I would like to have a quote for a project about:

${encodeURIComponent(details)}

You can contact me with the following information:
---------------------------
Full Name: ${encodeURIComponent(fullName)}
Email: ${encodeURIComponent(email)}
Services asked: ${encodeURIComponent(services.join(', '))}
Formule asked: ${encodeURIComponent(formule.join(', '))}
Delay needed: ${encodeURIComponent(delay.join(', '))}
`.replace(/\n/g, '%0D%0A');

        // Ouvrir le client de messagerie
        window.location.href = mailtoLink;
    }

    return (
        <section className="section_contact_form">
            <div className='section_contact_form_container'>
                <div className='section_contact_form_container_title_container'>
                    <div className='section_contact_form_container_title'>
                        Price ?
                    </div>
                    <div className='section_contact_form_container_subtitle'>
                        It's depend of what <span>YOU</span> need
                    </div>
                    <form className='section_contact_form_container_form' onSubmit={SendMail}>
                        <input
                            type='text'
                            placeholder='Full Name'
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <input
                            type='email'
                            placeholder='Email'
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className='section_contact_form_container_form_checkbox_frame'>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='service[]' id='1' value='Vitrine' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='1'>Vitrine</label>
                                <input type='checkbox' name='service[]' id='2' value='E-Commerce' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='2'>E-Commerce</label>
                                <input type='checkbox' name='service[]' id='3' value='Services' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='3'>Services</label>
                                <input type='checkbox' name='service[]' id='4' value='Other' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='4'>Other</label>
                            </div>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='formule[]' id='5' value='Design & Dev' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='5'>Design & Dev</label>
                                <input type='checkbox' name='formule[]' id='6' value='Only Dev' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='6'>Only Dev</label>
                                <input type='checkbox' name='formule[]' id='7' value='Only Design' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='7'>Only Design</label>
                            </div>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='delay[]' id='8' value='Urgent Delay' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='8'>Urgent Delay</label>
                                <input type='checkbox' name='delay[]' id='9' value='No stress' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='9'>No stress</label>
                                <input type='checkbox' name='delay[]' id='10' value='Just for curiosity' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='10'>Just for curiosity</label>
                            </div>
                        </div>
                            <textarea
                                placeholder='More details here...'
                                value={details}
                                onChange={(e) => setDetails(e.target.value)}
                            />
                        <button type='submit'>Send <img src="/svg/icon_send.svg" alt="Send" /></button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Section_form;
