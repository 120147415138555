import './section_workflow.css';

function Section_workflow() {
    return (
        <section className='section_workflow'>
            <div className='section_workflow-container'>
                <h1 className='section_workflow_container-title'>
                    Workfow
                </h1>
                <h2 className='section_workflow_container-subtitle'>
                    Process
                </h2>
                <div className='section_workflow_container-graph'>
                    <img src="/svg/graph_road_1.svg" alt="" />
                    <img src="/svg/graph_road_2.svg" alt="" />
                    <img src="/svg/graph_road_3.svg" alt="" />
                    <img src="/svg/graph_road_4.svg" alt="" />
                </div>
            </div>
        </section>
    )
}

export default Section_workflow;