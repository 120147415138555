import './section_contact.css';
import Button_contact from '../button/button_contact';

function Section_contact() {
    return (
        <section id='contact' className='section_contact'>
            <div className='section_contact_container'>
                <h1 className='section_contact_container_title'>
                    Have a question ?
                </h1>
                <h2 className='section_contact_container_subtitle'>
                    Estimate response time: <span>70</span>min
                </h2>
                <div className='section_contact_container_2'>
                    <div>
                        <div>
                            Stéphane Duverney
                        </div>
                        <div className='section_contact_container_2_job_subtitle'>
                            UX/UI Web Developper
                        </div>
                    </div>
                    <div>
                        <Button_contact />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section_contact;