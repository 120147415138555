import './section_about.css';
import Button_contact from '../button/button_contact';

function section_about() {
    return (
        <section id='about' className='section_about'>
            <div className='section_about-container'>
                <div className='section_about-container_left'>
                    <div className='section_about-container_left_title'>
                        About
                    </div>
                    <div>
                        <div className='section_about-container_left_name'>
                            Stéphane Duverney
                        </div>
                        <div className='section_about-container_left_job-title'>
                            UX/UI Web Developper
                        </div>
                    </div>
                </div>
                <div className='section_about-container_right'>
                    <div>
                        Hi! I’m Stéphane, a product designer and full-stack developer based in Lyon. I specialize in crafting user-centric designs that are both visually appealing and highly functional. With my full-stack development skills, I seamlessly translate these designs into clean, efficient code.
                    </div>
                </div>
            </div>
            <div className='section_about-container_right_btn'>
                <Button_contact />
            </div>
        </section>
    );
}

export default section_about;