import './section_design.css';

function Section_design() {
    return (
        <section id='process' className="section_design">
            <div className='section_design_container'>
                <div className='section_design_container_image section_design_img_1'>
                    {/* <div className='section_design_container_image_title'>
                        <h2>Design</h2>
                        <p>Process</p>
                    </div>
                    <div className='section_design_container_image_number'>
                        01
                    </div> */}
                    <img src="/img/section_design.png" alt="" />
                </div>
            </div>
        </section>
    );
}

export default Section_design;