import './app_chat.css'

function app_chat() {
    return (
        <div className='app_chat'>
            <img src="/svg/icon_chat.svg" alt="" />
        </div>
    )
}

export default app_chat;